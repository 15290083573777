import React from 'react';
import Layout from "../components/Layout";
import HolidaysListStyles from "./HolidaysList.module.scss";
import * as components from '../components';

function HolidaysList() {
    const daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monsthsInYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    function getDayOfaWeek(dt) {
        return daysInWeek[new Date(dt).getDay()];
    }
    
    function getMonthOfYear(dt) {
        return monsthsInYear[new Date(dt).getMonth()];
    }
    // TODO: formatting date
    const tableProps = {
        columns: [
            { key: 'month', name: 'Month'},
            { key: 'date', name: 'Date' },
            { key: 'day', name: 'Day'},
            { key: 'ocassion', name: 'Ocassion'},
        ],
        data: [
            { key: 1, day: getDayOfaWeek('1/1/2021'), date: '1/1/2021', ocassion: 'New Year', month: getMonthOfYear('1/1/2021') },
            { key: 2, day: getDayOfaWeek('1/14/2021'), date: '14/1/2021', ocassion: 'Pongal/ Sankrant', month: getMonthOfYear('1/14/2021') },
            { key: 3, day: getDayOfaWeek('01/26/2021'), date: '26/01/2021', ocassion: 'Republic day', month: getMonthOfYear('01/26/2021') },
            { key: 4, day: getDayOfaWeek('03/29/2021'), date: '29/03/2021', ocassion: 'Holi (Colour)', month: getMonthOfYear('03/29/2021') },
            { key: 5, day: getDayOfaWeek('04/13/2021'), date: '13/04/2021', ocassion: 'Ugadi', month: getMonthOfYear('04/13/2021') },
            { key: 6, day: getDayOfaWeek('05/12/2021'), date: '12/05/2021', ocassion: 'Ramjan/Eid', month: getMonthOfYear('05/12/2021') },
            { key: 7, day: getDayOfaWeek('09/10/2021'), date: '10/09/2021', ocassion: 'Ganesh Chaturthy', month: getMonthOfYear('09/10/2021') },
            { key: 8, day: getDayOfaWeek('10/15/2021'), date: '15/10/2021', ocassion: 'Dussehra', month: getMonthOfYear('10/15/2021') },
            { key: 9, day: getDayOfaWeek('11/1/2021'), date: '1/11/2021', ocassion: 'Karnataka Rajutsav', month: getMonthOfYear('11/1/2021') },
            { key: 10, day: getDayOfaWeek('11/5/2021'), date: '5/11/2021', ocassion: 'Diwali', month: getMonthOfYear('11/5/2021') },
        ]
    }

    return (
        <Layout>
            <div className={HolidaysListStyles.holidayTable}>
                <components.Table {...tableProps}/>
            </div>
        </Layout>
    )
}

export default HolidaysList
